import React from 'react'

function SignupSuccess() {
  return (
    <div className="container">
      <div className="app-wrapper">
        <h1 className="form-success">Account Created!</h1>
      </div>
    </div>
  )
}

export default SignupSuccess
